<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Yêu cầu xác thực tài khoản</h6>
        </template>
        <b-row>
          <b-col class="col-md-3">
            <b-form-group label="Từ khóa">
              <b-form-input
                v-model="filter.keyword"
                placeholder="Tên, Email, SĐT"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-md-3">
            <b-form-group label="Trạng thái">
              <b-form-select
                :options="status"
                v-model="filter.status"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col class="col-md-3">
            <b-form-group label="Ngày bắt đầu">
              <b-form-input
                :id="`type-date`"
                :type="`date`"
                v-model="filter.date_from"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-md-3">
            <b-form-group label="Ngày kết thúc">
              <b-form-input
                :id="`type-date`"
                :type="`date`"
                v-model="filter.date_to"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-md-3">
            <b-form-group label="Loại duyệt">
              <b-form-select
                :options="type"
                v-model="filter.type"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col">
            <div class="text-right">
              <a class="mr-2 btn btn-outline-success" type="submit" href="/#/tools/user/create-request-verify"
              > + Tạo mới
              </a>
              <b-button class="mr-2" type="submit" variant="primary" @click="resetSearch"
                >Đặt lại
              </b-button>
              <b-button variant="outline-info" @click="search"
                >Tìm kiếm</b-button
              >
            </div>
          </b-col>
        </b-row>

        <hr />
        <div v-if="paginate.total > 0">
          <span>Tổng số: </span
          ><strong>{{ numberFormat(paginate.total) }}</strong>
        </div>
        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
          <template #cell(index)="data">
            {{ (currentPage - 1) * 25 + (data.index + 1) }}
          </template>

          <template #cell(status)="data">
            <span
              :class="`rounded p-1 bg-${
                convertStatusLabel(data.item.status).class
              }`"
              >{{ convertStatusLabel(data.item.status).label }}</span
            >
          </template>
          <template #cell(updated_by)="data">
            <span>{{ getAdminName(data.item.updated_by) }}</span>
          </template>

          <template #cell(options)="data" class="text-right">
            <b-button-group size="sm">
              <a
                class="btn btn-sm btn-outline-success btn-primary"
                :href="detail(data.item.request_id)"
                target="_blank"
                >Chi tiết</a
              >
            </b-button-group>
          </template>
        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>
<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import router from "@/router";

const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
  mixins: [Common],
  components: {},
  data() {
    return {
      items: [],
      listAdmin: [],
      item: {
        name: null,
        time_frame: null,
        message: null,
        status: null,
        params: {},
      },
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      filter: {
        keyword: null,
        status: null,
        type: null,
        date_from: this.getFirstDayOfMonth(),
        date_to: this.getCurrentDay(),
      },
      fields: [
        { index: "STT" },
        { name: "Họ tên" },
        { email: "Email" },
        { phone: "Số điện thoại" },
        { status: "Trạng thái" },
        { reason: "Lý do" },
        { created_at: "Ngày tạo" },
        { updated_by: "Người duyệt" },
        { updated_at: "Ngày cập nhật" },
        { options: "Hành động" },
      ],
      status: [
        { value: "", text: "Tất cả" },
        { value: "0", text: "Đang chờ" },
        { value: "1", text: "Đồng ý" },
        { value: "2", text: "Từ chối" },
        { value: "9", text: "Hồ sơ phụ đang chờ" },
      ],
      type: [
        { value: "AUTO", text: "Tự động" },
        { value: "MANUAL", text: "Duyệt tay" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Yêu cầu xác thực tài khoản",
        route: "/#/tools/user/request-verify",
      },
    ]);
  },
  methods: {
    getAdminName(id) {
      if (parseInt(id) === 0 || !id) {
        return "Hệ thống";
      }
      return this.listAdmin.find((admin) => admin.user_id === id).fullname;
    },
    convert(params) {
      for (let key in params) {
        if (params[key] == "" || params[key] == null) {
          delete params[key];
        }
      }
      return params;
    },
    resetSearch() {
      this.filter = {
        keyword: null,
        status: null,
        type: null,
        date_from: this.getFirstDayOfMonth(),
        date_to: this.getCurrentDay(),
      };
      this.search();
    },
    search() {
      this.filter.page = 1;
      router
        .push({ path: "/#/tools/user/request-verify", query: this.filter })
        .catch(() => {});

      if (this.currentPage !== 1) {
        this.currentPage = 1;
        return;
      }

      this.getList(this.currentPage);
    },
    getList(page = 1) {
      this.items = [];
      let params = { ...this.filter };
      params = this.convert(params);
      params.page = page;
      this.$bus.$emit("show-loading", true);
      CmsRepository.listRequestVerifyAccount(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert(
              "warn",
              "Có lỗi xẩy ra, vui lòng thử lại hoặc liên hệ admin."
            );
            return;
          }

          const data = response.data.data;

          this.items = data.response.data;
          this.paginate.total = data.response.total;
          this.paginate.totalPage = data.response.last_page;
          this.paginate.currentPage = data.response.current_page;
          this.listAdmin = data.list_admin;
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    detail(id) {
      return "/#/tools/users/account/view/" + id;
    },
    convertStatusLabel(status) {
      let arrStatus = {
        0: { class: "warning", label: "Chờ phê duyệt" },
        1: { class: "danger", label: "Từ chối" },
        2: { class: "success", label: "Đã duyệt" },
        9: { class: "warning", label: "Hồ sơ phụ đang chờ" },
      };

      if (arrStatus[status] === undefined) {
        return { class: "warning", label: "Chờ phê duyệt" };
      }

      return arrStatus[status];
    },
  },
  created() {
    this.getList();
  },
  watch: {
    currentPage() {
      this.getList(this.currentPage);
    },
  },
};
</script>
