<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Đối soát cuộc gọi</h6>
        </template>
        <div class="filter">
          <b-row>
            <b-col>
              <b-form-group label="Từ ngày">
                <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="filter.date_from"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Đến ngày">
                <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="filter.date_to"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Số tổng đài">
                <b-form-input
                  v-model="filter.switchboard_number"
                  placeholder="Nhập số tổng đài"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Loại cuộc gọi">
                <b-form-select
                  :options="directions"
                  v-model="filter.direction"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="SĐT khách hàng">
                <b-form-input
                  v-model="filter.customer_phone"
                  placeholder="Nhập SĐT khách hàng"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Brandname">
                <b-form-select
                  :options="brands"
                  v-model="filter.brand"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Trạng thái">
                <b-form-select
                  :options="status"
                  v-model="filter.status"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="text-right">
                <b-button-group>
                  <b-button
                    type="button"
                    variant="primary"
                    @click="search()"
                    class="mr-2"
                    ><i class="fa fa-search"></i> Tìm kiếm</b-button
                  >
                  <b-button type="button" variant="success" @click="showModal()"
                    ><i class="fa fa-download"></i> Xuất Excel</b-button
                  >
                </b-button-group>
              </div>
            </b-col>
          </b-row>
        </div>
        <div>
          <b-row>
            <b-col md="3">
              <span class="font-weight-bold text-primary">
                Số cuộc gọi ra: {{ numberFormat(count_outbound) }}
              </span>
            </b-col>
            <b-col md="3">
              <span class="font-weight-bold text-primary">
                Tổng thời lượng gọi ra: {{ numberFormat(sum_totalduration) }} s
              </span>
            </b-col>
          </b-row>
        </div>
        <div class="content">
          <summary-table
            v-if="items"
            :total="paginate.total"
            :perPage="paginate.limit"
            :currentPage="paginate.page"
            :totalPage="paginate.totalPage"
          ></summary-table>
          <b-table
            :items="items"
            :fields="fields"
            ref="table"
            striped
            hover
            responsive
            caption-top
          >
            <template #cell(index)="data">
              {{ (currentPage - 1) * 25 + (data.index + 1) }}
            </template>
          </b-table>
          <div class="mt-3">
            <b-pagination-nav
              v-model="currentPage"
              :link-gen="createLinkPage"
              :number-of-pages="paginate.totalPage"
              use-router
              first-number
              last-number
              align="right"
            ></b-pagination-nav>
          </div>
        </div>
      </b-card>
    </b-card-group>

    <export-modal :modalId="exportId" @exportData="exportData"></export-modal>
    <alert-dialogue ref="confirmDialogue"></alert-dialogue>
  </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import ExportModal from "@/view/pages/components/ExportModal.vue";
import AlertDialogue from "@/view/pages/components/AlertModal.vue";
import router from "@/router";
import moment from "moment-timezone";
import SummaryTable from "@/view/pages/components/Summary.vue";

Vue.use(Notifications);

const ReconcileRepository = RepositoryFactory.get("reconcile");
export default {
  components: { SummaryTable, AlertDialogue, ExportModal },
  mixins: [Common],
  data() {
    return {
      exportId: "export_south_telecom_reconcile",
      items: null,
      groupItems: [],
      currentPage: 1,
      count_outbound: 0,
      sum_totalduration: 0,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      filter: {
        date_from: this.getFirstDayOfMonth(),
        date_to: this.getCurrentDay(),
        status: "0",
        switchboard_number: null,
        customer_phone: null,
        network: "0",
        brand: "0",
        direction: "0",
      },
      networks: [
        { value: "0", text: "Tất cả" },
        { value: 1, text: "Viettel" },
        { value: 2, text: "Mobi" },
        { value: 3, text: "Vinaphone" },
      ],
      status: [
        { value: "0", text: "Tất cả" },
        { value: 1, text: "Thất bại" },
        { value: 2, text: "Thành công" },
      ],
      brands: [
        { value: "0", text: "Tất cả" },
        { value: 1, text: "Loa Tingting" },
        { value: 2, text: "9Pay" },
      ],
      directions: [
        { value: "0", text: "Tất cả" },
        { value: "outbound", text: "Gọi ra" },
        { value: "inbound", text: "Gọi vào" },
      ],
      fields: [
        {
          key: "index",
          label: "STT",
        },
        {
          key: "brand_name",
          label: "Brandname",
        },
        {
          key: "server",
          label: "Số tổng đài",
        },
        {
          key: "server_network",
          label: "Nhà mạng",
        },
        {
          key: "direction_str",
          label: "Loại cuộc gọi",
        },
        {
          key: "internal_network",
          label: "Loại cước",
        },
        {
          key: "totalduration",
          label: "Thời lượng cuộc gọi (s)",
        },
        {
          key: "customernumber",
          label: "SĐT khách hàng",
        },
        {
          key: "customer_network",
          label: "Nhà mạng của khách hàng",
        },
        {
          key: "status_str",
          label: "Trạng thái",
        },
        {
          key: "time_format",
          label: "Thời gian",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Đối soát cuộc gọi", route: "call-logs" },
    ]);
  },
  methods: {
    moment,
    search() {
      this.filter.page = 1;
      router
        .push({ path: "/reconcile/call-logs", query: this.filter })
        .catch(() => {});

      if (this.currentPage !== 1) {
        this.currentPage = 1;
        return;
      }

      this.getTransactions();
    },
    async getTransactions() {
      this.$bus.$emit("show-loading", true);
      this.items = null;
      let params = this.convert(this.filter);
      params.page = this.currentPage;
      let response = await ReconcileRepository.getCallLogs(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;
      if (body.error_code) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }
      body = body.data;
      this.items = body.data;
      this.paginate.totalPage = body.last_page;
      this.paginate.total = body.total;
      this.paginate.page = body.current_page;
      this.paginate.limit = body.per_page;
      this.count_outbound = body.count_outbound;
      this.sum_totalduration = body.sum_totalduration;
    },
    showModal() {
      this.$bvModal.show(this.exportId);
    },
    async exportData(description = null) {
      this.$bus.$emit("show-loading", true);
      let params = this.convert(this.filter);
      params.export_desc = description;
      this.$bvModal.hide(this.exportId);

      let response = await ReconcileRepository.exportCallLog(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;
      if (body.error_code) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }

      this.$bus.$emit("show-loading", false);
      let link = window.location.origin + "/#/reconcile/export-data";
      let cfm = await this.$refs.confirmDialogue.show({
        title: "Download File",
        message:
          "File đang được xử lý, bạn có thể tải xuống sau ít phút <a href='" +
          link +
          "' target='_blank'>TẠI ĐÂY</a>",
        okButton: "Ok",
      });

      if (!cfm) {
        self.$bus.$emit("show-loading", false);
        return (self.errorMessage = "Có lỗi khi lấy thông tin");
      }
    },
    convert(params) {
      Object.keys(params).forEach((key) => {
        if (params[key] === null || params[key] == "") {
          delete params[key];
        }
      });
      return params;
    },
  },
  created() {
    this.getTransactions();
  },
  watch: {
    currentPage(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getTransactions();
      }
    },
  },
};
</script>
