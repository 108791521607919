<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Thêm yêu cầu định danh</h6>
        </template>
        <div class="m-portlet__body clearfix">
          <div class="row">
            <div class="col-md-12">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th width="100%" colspan="2" style="background: #c4c4c4">
                      Thông tin ảnh
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td style="width: 50%">
                      <b-form-group label="Ảnh (1) CMND/Thẻ CCCD/Hộ chiếu (*):">
                        <div>
                          <b-form-file
                            @change="onImageChange($event, 'img_front_card')"
                            placeholder="chọn ảnh"
                            accept="image/*"
                            type="file"
                          ></b-form-file>
                          <div
                            style="margin-top: 10px"
                            v-if="form.img_front_card"
                          >
                            <img
                              style="width: 100px"
                              :src="getUrlImage(form.img_front_card)"
                              alt=""
                            />
                          </div>
                          <has-error
                            :form="form"
                            field="img_front_card"
                          ></has-error>
                        </div>
                      </b-form-group>
                    </td>
                    <td>
                      <b-form-group label="Ảnh (2) CMND/Thẻ CCCD/Hộ chiếu (*):">
                        <div>
                          <b-form-file
                            @change="onImageChange($event, 'img_back_card')"
                            placeholder="chọn ảnh"
                            accept="image/*"
                            type="file"
                          ></b-form-file>
                          <div
                            style="margin-top: 10px"
                            v-if="form.img_back_card"
                          >
                            <img
                              style="width: 100px"
                              :src="getUrlImage(form.img_back_card)"
                              alt=""
                            />
                          </div>
                          <has-error
                            :form="form"
                            field="img_back_card"
                          ></has-error>
                        </div>
                      </b-form-group>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b-form-group label="Ảnh (3) chụp chân dung">
                        <div>
                          <b-form-file
                            @change="onImageChange($event, 'selfie_image')"
                            placeholder="chọn ảnh"
                            accept="image/*"
                            type="file"
                          ></b-form-file>
                          <div
                            style="margin-top: 10px"
                            v-if="form.selfie_image"
                          >
                            <img
                              style="width: 100px"
                              :src="getUrlImage(form.selfie_image)"
                              alt=""
                            />
                          </div>
                          <has-error
                            :form="form"
                            field="selfie_image"
                          ></has-error>
                        </div>
                      </b-form-group>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="start_time"
                          >Nhập số tài khoản ví
                          <span class="text-danger">*</span></label
                        >
                        <div class="input-group">
                          <input
                            type="number"
                            class="form-control"
                            name="phone"
                            value=""
                            required=""
                            v-model="form.phone"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="card_type"
                          >Chọn loại giấy tờ
                          <span class="text-danger">*</span></label
                        >
                        <select
                          class="form-control card_type"
                          name="card_type"
                          required
                          v-model="form.card_type"
                        >
                          <option value="">Chọn loại giấy tờ</option>
                          <option value="1">Chứng minh thư</option>
                          <option value="2">Căn cước công dân</option>
                          <option value="3">Hộ chiếu</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="start_time"
                          >Tên chủ thẻ<span class="text-danger">*</span></label
                        >
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control text-uppercase"
                            name="name"
                            value=""
                            required=""
                            v-model="form.name"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="start_time"
                          >Nơi cấp<span class="text-danger">*</span></label
                        >
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control text-uppercase"
                            value=""
                            name="card_issue_place"
                            required=""
                            v-model="form.card_issue_place"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="start_time"
                          >Số CMT/CCCD<span class="text-danger">*</span></label
                        >
                        <div class="input-group">
                          <input
                            type="number"
                            class="form-control"
                            name="id_card_no"
                            value=""
                            required=""
                            v-model="form.id_card_no"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="start_time"
                          >Số hộ chiếu
                          <span class="text-danger"
                            >(bắt buộc nếu giấy tờ là hộ chiếu)</span
                          ></label
                        >
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control passport_number"
                            name="passport_number"
                            value=""
                            v-model="form.passport_number"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="start_time"
                          >Giới tính<span class="text-danger">*</span></label
                        >
                        <div class="input-group">
                          <select
                            class="form-control"
                            name="gender"
                            v-model="form.gender"
                          >
                            <option value="">Chọn giới tính</option>
                            <option value="1">Nam</option>
                            <option value="2">Nữ</option>
                            <option value="3">N/A</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="start_time"
                          >Tỉnh/ Thành phố<span class="text-danger"
                            >*</span
                          ></label
                        >
                        <div class="input-group">
                          <input
                            v-model="form.province"
                            type="text"
                            class="form-control text-uppercase"
                            name="province"
                            value=""
                            required=""
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="start_time"
                          >Ngày phát hành thẻ<span class="text-danger"
                            >*</span
                          ></label
                        >
                        <div class="input-group">
                          <input
                            type="date"
                            class="form-control m_datepicker"
                            name="card_issue_at"
                            value=""
                            required=""
                            v-model="form.card_issue_at"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text">
                              <i class="fas fa-calendar-alt"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="start_time"
                          >Ngày hết hạn thẻ<span class="text-danger"
                            >*</span
                          ></label
                        >
                        <div class="input-group">
                          <input
                            type="date"
                            class="form-control m_datepicker"
                            name="card_expired_at"
                            value=""
                            required=""
                            v-model="form.card_expired_at"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text">
                              <i class="fas fa-calendar-alt"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="start_time"
                          >Ngày sinh <span class="text-danger">*</span></label
                        >
                        <div class="input-group">
                          <input
                            type="date"
                            class="form-control m_datepicker"
                            name="birthday"
                            value=""
                            required=""
                            v-model="form.birthday"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text">
                              <i class="fas fa-calendar-alt"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group text-center">
                <button type="submit" class="btn btn-primary btn-loader" @click="create">
                  Lưu
                </button>
                <a
                  href="/#/tools/user/create-request-verify"
                  class="btn btn-danger"
                  >Hủy</a
                >
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>
<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const CmsRepository = RepositoryFactory.get("cms");

export default {
  mixins: [Common],
  data() {
    return {
      form: {
        name: null,
        phone: null,
        card_holder_name: null,
        birthday: null,
        gender: null,
        province: null,
        card_type: null,
        card_issue_at: null,
        card_issue_place: null,
        card_expired_at: null,
        passport_number: null,
        id_card_no: null,
        img_front_card: null,
        img_back_card: null,
        selfie_image: null,
      },
    };
  },
  methods: {
    getUrlImage(field) {
      return URL.createObjectURL(field);
    },
    onImageChange(e, field) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.form[field] = files[0];
    },
    create() {
      this.$bus.$emit("show-loading", true);

      let param = new FormData();
      param.append("name", this.form.name);
      param.append("phone", this.form.phone);
      param.append("card_holder_name", this.form.card_holder_name);
      param.append("birthday", this.form.birthday);
      param.append("gender", this.form.gender);
      param.append("province", this.form.province);
      param.append("card_type", this.form.card_type);
      param.append("card_issue_at", this.form.card_issue_at);
      param.append("card_issue_place", this.form.card_issue_place);
      param.append("card_expired_at", this.form.card_expired_at);
      param.append("passport_number", this.form.passport_number);
      param.append("id_card_no", this.form.id_card_no);
      param.append("img_front_card", this.form.img_front_card);
      param.append("img_back_card", this.form.img_back_card);
      param.append("selfie_image", this.form.selfie_image);

      CmsRepository.createRequestVerifyAccount(param)
        .then((response) => {
          if (!response.data.success) {
            this.notifyAlert("warn", response.data.message || 'Có lỗi xảy ra, vui lòng thử lại sau');
            this.$bus.$emit("show-loading", false);
            return;
          }

          window.location.href = "/#/tools/user/request-verify";
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
  },
};
</script>
