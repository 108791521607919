<template>
  <div>
    <div
      variant="light"
      class="justify-content-end container alert alert-white alert-shadow"
      style="padding-top: 10px"
    >
      <div class="row">
        <b-col md="3">
          <b-container fluid>
            <b-col><label :for="`startDate`">Ngày bắt đầu :</label></b-col>
            <b-col>
              <b-form-input
                :id="`type-date`"
                :type="`date`"
                v-model="searchData.startDate"
              ></b-form-input>
            </b-col>
          </b-container>
        </b-col>
        <b-col md="3">
          <b-container fluid>
            <b-col><label :for="`endDate`">Ngày kết thúc :</label></b-col>
            <b-col>
              <b-form-input
                :id="`type-date`"
                :type="`date`"
                v-model="searchData.endDate"
              ></b-form-input>
            </b-col>
          </b-container>
        </b-col>
        <b-col md="3">
          <b-container fluid>
            <b-col><label>Loại dữ liệu :</label></b-col>
            <b-col>
              <b-form-select v-model="searchData.exportType">
                <option value="0">Tất cả</option>
                <option value="1">Chi tiết giao dịch</option>
                <option value="2">Đối soát</option>
                <option value="3">Số dư tài khoản theo ngày</option>
                <option value="5">Lịch sử giao dịch</option>
                <option value="6">Giao dịch đảo</option>
                <option value="7">Báo cáo</option>
                <option value="8">Combo Voucher</option>
                <option value="9">Danh sách thẻ</option>
                <option value="10">Khóa tài khoản</option>
                <option value="11">Danh sách tài khoản</option>
                <option value="15">Danh sách quà tặng</option>
              </b-form-select>
            </b-col>
          </b-container>
        </b-col>
      </div>
      <div class="row col-md-12 justify-content-end">
        <button
          ref="submit-search"
          class="btn btn-elevate btn-primary font-weight-bold px-11 py-2 my-4 font-size-3"
          @click="getExportDataRecords()"
        >
          Search
        </button>
      </div>
    </div>
    <div id="detail" class="row">
      <div class="col-md-12">
        <b-card-group deck>
          <b-card header-tag="header" footer-tag="footer">
            <template v-slot:header>
              <h6 class="mb-0">Danh sách xuất dữ liệu</h6>
            </template>
            <b-table
              ref="table"
              striped
              hover
              responsive
              caption-top
              :busy="exportData.tableLoading"
              :items="exportData.items"
              :fields="exportData.fields"
              show-empty
            >
              <template #cell(export_type)="data">
                {{ convertTypeExport(data.value) }}
              </template>
              <template #cell(created_at)="data">
                {{ data.value | formatDate }}
              </template>
              <template #cell(status)="data">
                <strong :style="getColorStatus('SUCCESS', 'TRANSACTION')">{{
                  convertStatus(data.value)
                }}</strong>
              </template>
              <!--<template #cell(action)="data">-->
              <!--<b-link v-if="data.item.status == 1" :href="data.item.storage_file">Download</b-link>-->
              <!--</template>-->
              <template #cell(action)="data">
                <b-link v-if="data.item.status == 1" :href="data.item.storage_file"><i class="fa fa-download"></i> Download</b-link>
              </template>
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>...</strong>
                </div>
              </template>
              <template #empty="scope"> </template>
            </b-table>
            <div class="mt-3">
              <b-pagination
                v-model="exportData.currentPage"
                :total-rows="exportData.paginate.total"
                :per-page="exportData.paginate.limit"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                @change="getExportDataRecords"
                align="right"
              ></b-pagination>
            </div>
          </b-card>
        </b-card-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Message from "@/core/config/message.config";

const ReconcileRepository = RepositoryFactory.get("reconcile");

export default {
  name: "ExportData",
  mixins: [Common],
  components: {},
  data() {
    return {
      searchData: {
        startDate: this.getYesterday(),
        endDate: this.getCurrentDay(),
        exportType: 0 // ALL
      },
      exportData: {
        fields: [
          {
            key: "id",
            label: "Id"
          },
          {
            key: "export_type",
            label: "Loại dữ liệu"
          },
          {
            key: "created_at",
            label: "Thời gian yêu cầu"
          },
          {
            key: "request_user",
            label: "User yêu cầu"
          },
          {
            key: "status",
            label: "Trạng thái"
          },
          {
            key: "export_desc",
            label: "Mô tả"
          },
          {
            key: "action",
            label: "Hành động"
          }
        ],
        items: [],
        tableLoading: false,
        currentPage: 1,
        paginate: {
          limit: 10,
          total: 0
        }
      }
    };
  },
  methods: {
    async getExportDataRecords(page) {
      try {
        this.exportData.tableLoading = true;
        const params = {
          export_type: this.searchData.exportType,
          from_date: this.searchData.startDate,
          to_date: this.searchData.endDate,
          page: page || 1,
          limit: this.exportData.paginate.limit
        };
        const response = await ReconcileRepository.listExportRecords(params);
        this.exportData.tableLoading = false;
        const body = response.data;
        this.exportData.items = body.data.list;
        this.exportData.paginate.total = body.data.paging.total;
      } catch (e) {
        this.exportData.tableLoading = false;
        return this.showError(Message.ERROR.SERVER);
      }
    },

    async downloadExportData(id) {
      try {
        this.$bus.$emit("show-loading", true);
        const params = {
          export_id: id
        };
        const response = await ReconcileRepository.downloadExportData(params);
        const body = response.data;
        if (body.error_code == 1) {
          return this.showError(body.message ?? Message.ERROR.DOWNLOAD_FILE);
        }
        await this.downloadXlsxFile("export_data_Id_" + id + ".csv", body);
        this.$bus.$emit("show-loading", false);
        return true;
      } catch (e) {
        this.$bus.$emit("show-loading", false);
        return this.showError(Message.ERROR.SERVER);
      }
    },

    convertStatus(status) {
      switch (status) {
        case 0:
          return "PENDING";
        case 1:
          return "SUCCESS";
        case -1:
          return "FAIL";
      }
    },

    convertTypeExport(type) {
      switch (type) {
        case 0:
          return "ALL";
        case 1:
          return "Chi tiết giao dịch";
        case 2:
          return "Đối soát giao dịch";
        case 3:
          return "Số dư theo ngày";
        case 5:
          return "Lịch sử giao dịch";
        case 6:
          return "Giao dịch đảo";
        case 7:
          return "Báo cáo";
        case 8:
          return "Combo voucher";
        case 9:
          return "Danh sách thẻ";
        case 10:
          return "Khóa tài khoản";
        case 11:
          return "Danh sách tài khoản";
        case 15:
          return "Danh sách quà tặng";
        case 16:
          return "Đối soát cuộc gọi";
        default:
          return "UNKNOWN";
      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },
  created() {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Xuất dữ liệu" }]);
  }
};
</script>
