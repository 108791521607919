<template>
  <li
    aria-haspopup="true"
    data-menu-toggle="hover"
    class="menu-item menu-item-submenu"
    v-bind:class="{ 'menu-item-open': hasActiveChildren('/reconcile') }"
    v-if="
      permissionsMiddleware([
        'exportData',
        'reconciles',
        'checkWallet',
        'Card',
        'Internal',
        'reconcileCashback',
        'charity',
        'vnpay',
        'vbee',
        'southTelecom',
        'reconcileUrbox',
        'reconcileMerchant',
        'reconcileNapasCashout',
        'reconcileKyc',
        'reconcileTransactionFee'
      ])
    "
  >
    <a href="#" class="menu-link menu-toggle">
      <i class="menu-icon flaticon2-rectangular"></i>
      <span class="menu-text">Đối soát</span>
      <i class="menu-arrow"></i>
    </a>
    <div class="menu-submenu">
      <span class="menu-arrow"></span>
      <ul class="menu-subnav">
        <li aria-haspopup="true" class="menu-item menu-item-parent">
          <span class="menu-link">
            <span class="menu-text">Đối soát</span>
          </span>
        </li>

        <router-link
          to="/reconcile/export-data"
          v-slot="{ href, navigate, isExactActive }"
          v-if="permissionMiddleware('exportData')"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[isExactActive && 'menu-item-active']"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Xuất dữ liệu</span>
            </a>
          </li>
        </router-link>
        <router-link
            to="/reconcile/reconciles"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('reconciles')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isExactActive && 'menu-item-active']"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Đối soát tự động</span>
            </a>
          </li>
        </router-link>
<!--        <router-link-->
<!--          to="/reconcile/cashback"-->
<!--          v-slot="{ href, navigate, isActive, isExactActive }"-->
<!--        >-->
<!--          <li-->
<!--            aria-haspopup="true"-->
<!--            data-menu-toggle="hover"-->
<!--            class="menu-item"-->
<!--            :class="[isExactActive && 'menu-item-active']"-->
<!--          >-->
<!--            <a :href="href" class="menu-link" @click="navigate">-->
<!--              <i class="menu-bullet menu-bullet-dot">-->
<!--                <span></span>-->
<!--              </i>-->
<!--              <span class="menu-text">Cashback</span>-->
<!--            </a>-->
<!--          </li>-->
<!--        </router-link>-->
        <router-link
                to="/reconcile/check-wallet"
                v-slot="{ href, navigate, isActive, isExactActive }"
                v-if="permissionMiddleware('checkWallet')"
        >
          <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Báo cáo đối soát bank</span>
            </a>
          </li>
        </router-link>

        <router-link
            to="/reconcile/card"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('Card')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Đối soát dịch vụ</span>
            </a>
          </li>
        </router-link>

        <router-link
            to="/reconcile/internal"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('Internal')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Đối soát nội bộ</span>
            </a>
          </li>
        </router-link>
        <router-link
            to="/reconcile/vnpay"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('vnpay')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Đối soát VNPAY</span>
            </a>
          </li>
        </router-link>
        <router-link
            to="/reconcile/charity"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('charity')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Đối soát từ thiện</span>
            </a>
          </li>
        </router-link>

        <router-link
            to="/reconcile/reconcile-cashback"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('reconcileCashback')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Đối soát CASHBACK</span>
            </a>
          </li>
        </router-link>
        <router-link
            to="/reconcile/vbee"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('vbee')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Đối soát VBee</span>
            </a>
          </li>
        </router-link>
        <router-link
            to="/reconcile/south-telecom"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('southTelecom')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Đối soát South Telecom</span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/reconcile/call-logs"
          v-slot="{ href, navigate, isActive, isExactActive }"
          v-if="permissionMiddleware('callLogs')"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Đối soát cuộc gọi</span>
            </a>
          </li>
        </router-link>
        <router-link
            to="/reconcile/urbox"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('reconcileUrbox')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Đối soát Urbox</span>
            </a>
          </li>
        </router-link>

        <router-link
            to="/reconcile/merchant"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('reconcileMerchant')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Đối soát VNTRIP</span>
            </a>
          </li>
        </router-link>
        <router-link
            to="/reconcile/napas/cashout"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('reconcileNapasCashout')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Đối soát napas cashout</span>
            </a>
          </li>
        </router-link>
        <router-link
            to="/reconcile/kyc"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('reconcileKyc')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Đối soát KYC</span>
            </a>
          </li>
        </router-link>
        <router-link
            to="/reconcile/transaction-fee"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('reconcileTransactionFee')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Đối soát phí giao dịch</span>
            </a>
          </li>
        </router-link>
      </ul>
    </div>
  </li>
</template>

<script>
import Common from "@/core/mixins/common";
import permissionMiddleware from "@/core/mixins/permissionMiddleware";
import permissionsMiddleware from "@/core/mixins/permissionsMiddleware";

export default {
  name: "SidebarReconcile",
  methods: { permissionMiddleware, permissionsMiddleware },
  mixins: [Common],
};
</script>

<style scoped></style>