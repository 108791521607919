<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Danh sách giao dịch</h6>
              </template>
              <div class="filter-transaction">
                <div class="row mb-6">
                  <div class="col-lg-3 mb-lg-0 mb-6">
                    <label>Mã thanh toán:</label>
                    <input
                      type="text"
                      v-model="filter.payment_id"
                      class="form-control datatable-input"
                      placeholder="Nhập mã payment id"
                    />
                  </div>
                  <div class="col-lg-2 mb-lg-0 mb-6">
                    <label>Trạng thái ngân hàng:</label>
                    <select
                      class="form-control datatable-input"
                      data-col-index="7"
                      v-model="filter.bank_status"
                    >
                      <option value="">Tất cả</option>
                      <option value="SUCCESS">SUCCESS</option>
                      <option value="FAIL">FAIL</option>
                      <option value="PENDING">PENDING</option>
                      <option value="CANCEL">CANCEL</option>
                    </select>
                  </div>
                  <div class="col-lg-2 mb-lg-0 mb-6">
                    <label>Trạng thái giao dịch:</label>
                    <select
                      class="form-control datatable-input"
                      data-col-index="7"
                      v-model="filter.transaction_status"
                    >
                      <option value="">Tắt cả</option>
                      <option value="success">Success</option>
                      <option value="fail">Fail</option>
                      <option value="pending">Pending</option>
                      <option value="create">Create</option>
                      <option value="create_success">Create success</option>
                      <option value="create_replace">Create replace</option>
                      <option value="offset">Offset</option>
                      <option value="refund">Refund</option>
                      <option value="reverse">Reverse</option>
                      <option value="wait_to_refund">Wait to refund</option>
                      <option value="cancel">Cancel</option>
                    </select>
                  </div>
                  <div class="col-lg-2 mb-lg-0 mb-6">
                    <label>Trạng thái hoá đơn:</label>
                    <select
                      class="form-control datatable-input"
                      data-col-index="7"
                      v-model="filter.order_status"
                    >
                      <option value="">Tắt cả</option>
                      <option value="create">Create</option>
                      <option value="created">Created</option>
                      <option value="success">Success</option>
                      <option value="fail">Fail</option>
                      <option value="payment_cancel">Payment cancel</option>
                      <option value="payment_pending">Payment pending</option>
                      <option value="payment_success">Payment success</option>
                      <option value="expired_session">Expired session</option>
                      <option value="processing">Processing</option>
                      <option value="wait_to_review">Wait to review</option>
                      <option value="reject">Reject</option>
                      <option value="cancel">Cancel</option>
                    </select>
                  </div>
                  <div class="col-lg-6 mb-lg-0 mb-6" style="margin-top: 20px">
                    <label>Ngày tạo:</label>
                    <div class="input-daterange input-group" id="kt_datepicker">
                      <input
                        type="date"
                        v-model="filter.start_date"
                        class="form-control datatable-input"
                        name="start"
                        placeholder="Từ"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="la la-ellipsis-h"></i>
                        </span>
                      </div>
                      <input
                        type="date"
                        v-model="filter.end_date"
                        class="form-control datatable-input"
                        name="end"
                        placeholder="Đến"
                      />
                    </div>
                  </div>
                  <div class="row mt-8" style="margin-top: 45px !important">
                    <div class="col-lg-12 text-right">
                      <button
                        class="btn btn-primary btn-primary--icon"
                        @click="searchTransaction()"
                      >
                        <span>
                          <i class="la la-search"></i>
                          <span>Tìm kiếm</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <b-table
                table-class="datatable-table"
                hover
                tbody-tr-class="datatable-row"
                thead-tr-class="datatable-row"
                thead-class="datatable-head"
                details-td-class="datatable-cell"
                :items="items"
                :fields="fields"
              >
                <template #cell(status)="data">
                  <span>
                    <span
                      :class="getColorStatus(data.value, 'TRANSACTION')"
                      style="text-transform: lowercase"
                      >{{ data.value }}</span
                    >
                  </span>
                </template>
                <template #cell(detail)="data">
                  <a :href="`${data.value}`">Xem</a>
                </template>
              </b-table>
              <b-card no-body class="mb-4" v-if="showError" align="center">
                <b-card-body>
                  <b-card-text>Không tìm thấy giao dịch</b-card-text>
                </b-card-body>
              </b-card>
              <div class="row mt-6">
                <div class="col-lg-12 text-right">
                  <span>Tổng tiền:</span><b> {{ total_amount }}</b>
                </div>
              </div>
              <div class="row mt-6">
                <div class="col-lg-12 text-center">
                  <strong v-if="this.loadingShowMore">Loading...</strong>
                  <b-button v-else @click="loadMore()" variant="outline-dark"
                    >Tải thêm</b-button
                  >
                </div>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const BankRepository = RepositoryFactory.get("bank");

export default {
  mixins: [Common],
  data() {
    return {
      filter: {},
      fields: [
        {
          key: "code",
          label: "Mã giao dịch",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "wallet_transaction_id",
          label: "Mã thanh toán",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "coupon_code",
          label: "Mã coupon",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "amount_f",
          label: "Giá tiền",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "action",
          label: "Loại GD",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "user_phone",
          label: "Phone",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "title",
          label: "Tiêu đề",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "status",
          label: "Trạng thái",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "order_status",
          label: "Trạng thái HD",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "created_at",
          label: "TT tạo",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "completed_at",
          label: "TT thanh toán",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "detail",
          label: "Chi tiết",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      items: [],
      currentPage: 1,
      loadingShowMore: false,
      totalAmount: 0,
      showError: false,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách giao dịch thẻ", route: "list" },
    ]);
    this.filter = this.$route.query;
    this.getListTransactionByCard();
  },
  methods: {
    searchTransaction() {
      this.currentPage = 1;
      this.getListTransactionByCard();
    },
    async loadMore() {
      this.loadingShowMore = true;
      this.currentPage += 1;
      let params = this.filter;
      params.page = this.currentPage;
      let request = await BankRepository.getListTransactionByBankCard(params);
      let response = request.data;
      if (response.error_code === 0) {
        response.data.list.data.forEach((value) => {
          value["detail"] =
            "/#/transactions/detail?transaction_id=" + value.code;
          this.items.push(value);
        });
      }
      this.loadingShowMore = false;
    },
    async getListTransactionByCard() {
      try {
        this.showError = false;
        this.$bus.$emit("show-loading", true);
        let params = this.filter;
        params.page = this.currentPage;
        let request = await BankRepository.getListTransactionByBankCard(params);
        this.$bus.$emit("show-loading", false);
        let response = request.data;
        if (response.error_code === 0) {
          response.data.list.data.forEach((value) => {
            value["detail"] =
              "/#/transactions/detail?transaction_id=" + value.code;
          });
          this.items = response.data.list.data;
          this.totalAmount = response.data.totalAmount;
        } else {
          this.showError = true;
          this.items = [];
          this.totalAmount = 0;
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
  },
  computed: {
    start_date() {
      return this.filter.start_date;
    },
    end_date() {
      return this.filter.end_date;
    },
    payment_id() {
      return this.filter.payment_id;
    },
    bank_status() {
      return this.filter.bank_status;
    },
    transaction_status() {
      return this.filter.transaction_status;
    },
    order_status() {
      return this.filter.order_status;
    },
    total_amount() {
      return parseInt(this.totalAmount).toLocaleString("pt-BR") + " VNĐ";
    },
  },
  watch: {
    start_date(val) {
      if (this.filter.hasOwnProperty("start_date") && val.length < 1) {
        this.$delete(this.filter, "start_date");
      }
    },
    end_date(val) {
      if (this.filter.hasOwnProperty("end_date") && val.length < 1) {
        this.$delete(this.filter, "end_date");
      }
    },
    payment_id(val) {
      if (this.filter.hasOwnProperty("payment_id") && val.length < 1) {
        this.$delete(this.filter, "payment_id");
      }
    },
    bank_status(val) {
      if (this.filter.hasOwnProperty("bank_status") && val.length < 1) {
        this.$delete(this.filter, "bank_status");
      }
    },
    transaction_status(val) {
      if (this.filter.hasOwnProperty("transaction_status") && val.length < 1) {
        this.$delete(this.filter, "transaction_status");
      }
    },
    order_status(val) {
      if (this.filter.hasOwnProperty("order_status") && val.length < 1) {
        this.$delete(this.filter, "order_status");
      }
    },
  },
};
</script>
