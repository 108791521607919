const routerNameComboVoucher = [
  "api/combo-voucher/script/{id}",
  "api/combo-voucher/script",
  "api/combo-voucher/script/list-all-script",
  "api/combo-voucher/script/data-to-create",
  "api/combo-voucher/client",
];
const routerNameRefer = [
  "api/refer/config",
  "api/refer",
  "api/campaign/report/refer/{id}",
  "api/campaign/report/refer",
];
const routerNameRank = [
  "api/cms/challenge-rank/{id}",
  "api/cms/challenge-rank",
  "api/cms/ads/list-all-direction",
];
const routerNameComboVoucherWhiteList = [
  "api/combo-voucher/whitelist/{id}",
  "api/combo-voucher/whitelist",
  "api/combo-voucher/client",
];
const routerNameComboVoucherEvent = [
  "api/combo-voucher/event/{id}",
  "api/campaign/combo/get-info-coupon/{id}",
  "api/combo-voucher/event",
  "api/combo-voucher/client",
];
const routerNameCampaignSetting = [
  "api/campaign/setting/updateSettingWlMission",
  "api/campaign/setting/getSettingWlMission",
];
const routerNameScriptCombo = [
  "api/combo-voucher/report/log-user-gifts",
  "api/combo-voucher/report/gift-release",
];
const routerNameCampaignReport = [
  "api/campaign/report/challenges",
  "api/campaign/report/missions",
  "api/campaign/report/challenge-process/{id}",
  "api/campaign/report/retry/{id}",
  "api/campaign/report/challenges-gift-release",
];
const routerNameReferReport = [
  "api/campaign/report/refer",
  "api/campaign/report/refer/{id}",
];
const routerNameTopService = [
  "api/top-service/{id}",
  "api/top-service",
  "api/top-service/analysis/{id}",
  "api/top-service/bot/{id}",
  "api/top-service/milestone/{id}",
  "api/top-service/export/{id}",
];

const routerNameLadiRule = ["api/campaign/setting/banner-config"];

const routerNameLadiGift = ["api/campaign/setting/gift-config"];

const routerNameStudentRefer = [
  "api/campaign/user-seeding/view/{id}",
  "api/campaign/user-seeding/update/{id}",
  "api/campaign/user-seeding/create",
  "api/campaign/university/import/import-file",
  "api/campaign/university/{id}",
  "api/campaign/university",
  "api/campaign/vh-resend-otp",
  "api/campaign/report/refer-students",
  "api/campaign/report/seeding-student",
  "api/refer-student/add-seeding",
  "api/refer-student/approve",
  "api/refer-student/config",
  "api/refer-student",
  "api/campaign/report/campaign-student",
];
const routerNameChallengeFaq = ["api/campaign/faq/{id}", "api/campaign/faq"];

const routerNameChallengeRule = ["api/campaign/rule/{id}", "api/campaign/rule"];
const routerNameCombo = [
  "api/campaign/combo/view/{id}",
  "api/campaign/combo/delete/{id}",
  "api/campaign/combo/update/{id}",
  "api/campaign/combo/store",
  "api/campaign/combo/listCombo",
  "api/campaign/combo/get-info-coupon/{id}",
];
const routerNameChallenge = [
  "api/campaign/challenge/view/{id}",
  "api/campaign/challenge/delete/{id}",
  "api/campaign/challenge/update/{id}",
  "api/campaign/challenge/store",
  "api/campaign/challenge/listChallenge",
];
const routerNameMission = [
  "api/campaign/mission/view/{id}",
  "api/campaign/mission/delete/{id}",
  "api/campaign/mission/update/{id}",
  "api/campaign/mission/store",
  "api/campaign/mission/listMission",
  "api/campaign/mission/getOptions",
];
const routerNameCategory = [
  "api/campaign/category",
  "api/campaign/category/{id}",
];

const routerNameCharity = [
  "api/charities/users",
  "api/charities/campaign/view/{id}",
  "api/charities/campaign/delete/{id}",
  "api/charities/campaign/store",
  "api/charities/campaign/list",
  "api/charities/organization/view/{id}",
  "api/charities/organization/delete/{id}",
  "api/charities/organization/update/{id}",
  "api/charities/organization/store",
  "api/charities/organization/list",
];

const routerNameCheckPermission = {
  //Công cụ - Thêm thẻ phát hành tại VN
  "add-bin-code": ["api/bank/add-bin-code"],

  // Admin quản lý và quyền truy cập
  "role-permission": ["api/admin/roles"],
  "detail-role": [
    "api/admin/roles/{role}",
    "api/admin/permission/group",
    "api/admin/roles/addPermission",
    "api/admin/roles",
  ],
  "detail-user": ["api/admin/roles/listAll", "api/admin/users/{user}"],
  "create-role": ["api/admin/permission/group"],
  permission: ["api/admin/permissions", "api/admin/permissions/{permission}"],
  "list-user": [
    "api/admin/users/roles",
    "api/admin/users/{user}",
    "api/admin/users/{user}/edit",
    "api/admin/users",
    "api/admin/users/store",
  ],

  // Giao dịch - Thanh toán tự động
  "auto-payment": [
    "api/auto-payment/transaction",
    "api/auto-payment/detail",
    "api/auto-payment/listAll",
  ],

  dashboard: [ "api/okr/available" ],

  // Tài khoản - tài khoản doanh nghiệp
  enterprise: ["api/account/enterprise/detail", "api/account/enterprise"],
  enterpriseDetail: ["api/account/enterprise/detail", "api/account/enterprise"],

  // Tài khoản - Tài khoản merchant
  merchant: [
    "api/cms/merchant/delete",
    "api/cms/merchant/update",
    "api/cms/merchant/listAll",
    "api/cms/merchant/list-parent",
    "api/cms/segment/list-merchant",
  ],
  merchantCreate: ["api/cms/merchant/store"],

  blackList: [
      "api/cms/black-list/add",
      "api/cms/black-list/remove",
      "api/cms/black-list/index",
  ],

  //Công cụ - Config notifications
  "config-notifications": [
    "api/cms/config-notification/listAll",
    "api/cms/config-notification/delete",
    "api/cms/config-notification/update",
    "api/cms/config-notification/create",
  ],

  // Thống kê chung
  stats: ["api/report/bu"],

  sourceMoney: ["api/stats/data-source-money", "api/stats/export-source-money"],
  buFuntap: ["api/report/bu"],

  "sync-status": [
    "api/transaction/list-sync-status",
    "api/transaction/action-sync-status",
  ],

  "credit-card-list": [
    "api/bank/credit-card/list",
    "api/bank/bank-card/list",
    "api/bank/credit-card/search",
  ],

  "credit-card-detail": ["api/bank/credit-card/list-transaction"],
  "bank-card-list": [
    "api/bank/bank-card/list",
    ],
  "bank-card-detail": ["api/bank/bank-card/list-transaction"],

  distributeHistory: ["api/voucher/distribute-history"],

  //Công cụ - Config Segment
  "config-segment": [
    "api/cms/segment/listAll",
    "api/cms/segment/create",
    "api/cms/segment/update",
    "api/cms/segment/delete",
    "api/cms/segment/store-by-file"
  ],
  "segment-user": ["api/cms/segment/segment-user"],

  //Công cụ - retry-card
  "retry-card": [
    "api/cms/cards/service",
    "api/cms/cards/product",
    "api/cms/cards/retry",
  ],

  //chiến dịch refer
  refer: [
    "api/campaign/report/refer/{id}",
    "api/campaign/report/refer",
    "api/refer",
  ],

  //Công cụ - Khuyến mãi
  promotion: ["api/promotion"],
  createPromotion: ["api/promotion/create"],
  updatePromotion: ["api/promotion/view/{id}"],

  //Công cụ - Hẹn giờ
  schedule: [
    "api/schedule/list",
    "api/schedule/accept/{id}",
    "api/schedule/delete/{id}"
  ],
  scheduleCreate: [
    "api/schedule/store",
    "api/schedule/list",
    "api/schedule/update/{id}",
    "api/cms/product/list-all"
  ],
  scheduleUpdate: [
    "api/schedule/view/{id}",
    "api/cms/product/list-all",
    "api/schedule/update/{id}",
  ],

  //Công cụ - Log on/off dịch vụ
  serviceLog: ["api/cms/service-log"],

  //Công cụ - Cấu hình phí
  fee: [
    "api/fee/get-list-config", //lấy danh sách
    "api/fee", // chi tiết
  ],
  "fee-create": ["api/fee/get-list-config", "api/fee/store"],
  "fee-view": ["api/fee/get-list-config", "api/fee/view"],
  riskScoring: [
      "api/cms/config-risk-point",
      "api/cms/config-risk-point/check-risk-point",
      'api/cms/config-risk-point/check-risk-criteria',
      "api/cms/config-risk-point/store",
      "api/cms/config-risk-point/update"
  ],
  cardList: ["api/card-store/list"],
  cardExport: ["api/card-store/export-card"],
  cardImport: ["api/card-store/import-card"],
  vnpay: [
    "api/reconcile/partners/vnpay",
    "api/reconcile/partners/vnpay/export",
  ],
  vbee: [
    "api/reconcile/notice/vbee",
    "api/reconcile/notice/vbee/group",
    "api/reconcile/notice/vbee/export",
  ],
  southTelecom: [
    "api/reconcile/notice/south-telecom",
    "api/reconcile/notice/south-telecom/group",
    "api/reconcile/notice/south-telecom/export",
  ],
  coupon: ["api/reconcile-wallet/coupon", "api/reconcile-wallet/coupon/export"],

  //Đối soát - Xuất dữ liệu
  exportData: [
    "api/reconcile/download-export-data",
    "api/reconcile/list-export-records",
  ],
  //Đối soát - Đối soát tự động
  reconciles: [
    "api/reconcile/upload",
    "api/reconcile/view/{id}",
    "api/reconcile",
  ],
  reconcilesUpload: ["api/reconcile/upload"],
  reconcilesDetail: ["api/reconcile/view/{id}"],
  //Đối soát - Đối soát bank/ dịch vụ/ nội bộ
  Card: [
    "api/reconcile/wallet/show-transaction",
    "api/reconcile/wallet/show-total-transaction",
    "api/reconcile/get-list-card-filter",
    "api/reconcile/get-list-card-filter",
  ],
  Internal: [
    "api/reconcile/wallet/show-transaction",
    "api/reconcile/wallet/show-total-transaction",
    "api/reconcile/get-list-card-filter",
    "api/reconcile/cashback",
    "api/reconcile/cashback/view/{id}",
  ],
  checkWallet: [
    "api/reconcile/wallet/show-transaction",
    "api/reconcile/wallet/show-total-transaction",
    "api/reconcile/get-list-card-filter",
  ],
  // Đối soát cashback
  reconcileCashback: [
    "api/reconcile/wallet/show-transaction",
    "api/reconcile/wallet/show-total-transaction",
  ],

  reconcileUrbox: [
    "api/loyalty/urbox",
  ],
  reconcileMerchant: [
    "api/reconcile/wallet/merchant", "api/reconcile/wallet/merchant/export"
  ],
  reconcileKyc: [
    "api/reconcile/partners/kyc",
    "api/reconcile/partners/kyc/export",
  ],

  reconcileTransactionFee: [
      "api/reconcile/wallet/transaction-fee",
      "api/reconcile/wallet/transaction-fee/export",
  ],

  reconcileNapasCashout: [ "api/reconcile/wallet/napas/cashout", "api/reconcile/wallet/napas/cashout/export"],

  reportFee: ["api/fee/report", "api/fee/report/export"],

  //Báo cáo - chi tiết bank
  reportBank: ["api/fee/report/detail", "api/fee/report/export"],
  //Báo cáo - chi tiết cổng
  reportPayGate: ["api/fee/report/detail", "api/fee/report/export"],
  //Báo cáo - chi tiết nhà cung cấp
  reportProvider: ["api/fee/report/detail", "api/fee/report/export"],
  //Báo cáo - chi tiết phí thu Khách hàng
  reportCustomer: ["api/fee/report/detail", "api/fee/report/export"],
  //Báo cáo top 10 giao dịch của khách hàng cá nhân có giao dịch nhiều nhất
  reportTopTen: ["api/risk/top-ten-users", "api/risk/top-ten-users/export"],
  //Báo cáo tình hình giao dịch
  reportTransactionSituation: ["api/risk/situation", "api/risk/situation/export"],
  riskNotification: [ "api/risk/notification", "api/risk/notification/update/{id}" ],

  // config route
  routeApp: ["api/route-app/list"],
  "routeApp-create": ["api/route-app/store", "api/route-app/view/{id}"],

  // config đeeplink
  configDeeplink: ["api/config-deeplink/list"],
  "configDeeplink-create": ["api/config-deeplink/store", "api/config-deeplink/view/{id}"],

  messageTemplate: ["api/message-template/list"],
  "messageTemplate-create": ["api/message-template/store", "api/message-template/view/{id}"],

  serviceApp: ["api/service-app/list"],
  "serviceApp-create": ["api/service-app/store", "api/service-app/view/{id}"],

  bounceMail: ["api/bounce-mail/list","api/bounce-mail/delete/{id}"],
  bounceMailCreate: ["api/bounce-mail/store", "api/bounce-mail/view/{id}","api/bounce-mail/delete/{id}"],

  mailMerchant: ["api/mail-merchant/list", "api/mail-merchant/delete/{id}"],
  mailMerchantCreate: ["api/mail-merchant/store", "api/mail-merchant/view/{id}"],

  charity: [
    "api/reconcile/wallet/charity",
    "api/reconcile/wallet/charity/export",
    "api/reconcile/wallet/charity/products",
    "api/reconcile/wallet/charity/providers",
  ],
  "with-voucher": [
    "api/transaction/with-voucher",
    "api/transaction/with-voucher/export",
  ],

  elastic: [
    "api/transaction/elastic",
    "api/reconcile/wallet/export-transaction",
  ],
  daily: ["api/daily-balance", "api/daily-balance/export"],

  pointDaily: ["api/daily-point", "api/daily-point/export"],

  "dashboard-marketing": [ "api/dashboard/services", "api/dashboard/marketing", "api/dashboard/marketing/export" ],

  mkt_voucher: [
      "api/marketing/report-voucher",
      "api/marketing/report-voucher/export",
      "api/marketing/user-transactions",
      "api/marketing/user-transactions/export",
  ],
  reportAccounts: [ "api/report/account-balance" ],
  reportNewAccounts: [ "api/report/new-account" ],

  bankMaintenance: [ "api/cms/bank/maintenance" ],
  createMaintenance: [ "api/cms/bank", "api/cms/bank/maintenance/store" ],
  updateMaintenance: [ "api/cms/bank", "api/cms/bank/maintenance/detail/{id}", "api/cms/bank/maintenance/update/{id}" ],

  bankMonitor: [ "api/cms/bank/monitor", "api/cms/bank/monitor/update/{id}" ],

  userRequestLock: [ "api/account/request-lock" ],
  userRequestLockDetail: [ "api/account/request-lock/detail/{id}" ],

  // refer
  "campaign-refer": routerNameRefer,
  campaign_refer: routerNameRefer,
  // refer student
  campaign_student_refer_config: routerNameRefer,
  campaign_student_refer: routerNameRefer,
  campaign_student_refer_seeding: routerNameRefer,
  campaign_student_refer_report: routerNameRefer,

  // từ thiện
  charity_config: routerNameCharity,
  organization: routerNameCharity,
  "organization-create": routerNameCharity,
  "organization-edit": routerNameCharity,
  "campaign-charities": routerNameCharity,
  "campaign-charities-create": routerNameCharity,
  "campaign-charities-edit": routerNameCharity,
  "campaign-users": routerNameCharity,

  //kịch bản phát quà

  "combo-voucher-script": routerNameComboVoucher,
  combo_voucher_script: routerNameComboVoucher,
  combo_voucher_script_create: routerNameComboVoucher,
  combo_voucher_script_edit: routerNameComboVoucher,

  "combo-voucher-whitelist": routerNameComboVoucherWhiteList,
  combo_voucher_whitelist_list: routerNameComboVoucherWhiteList,
  combo_voucher_whitelist_create: routerNameComboVoucherWhiteList,
  combo_voucher_whitelist_edit: routerNameComboVoucherWhiteList,

  combo_voucher_campaign: routerNameComboVoucherEvent,
  combo_voucher_campaign_create: routerNameComboVoucherEvent,
  combo_voucher_campaign_edit: routerNameComboVoucherEvent,

  campaign_university: routerNameStudentRefer,
  campaign_university_create: routerNameStudentRefer,
  campaign_university_edit: routerNameStudentRefer,
  campaign_university_upload: routerNameStudentRefer,
  report_student: routerNameStudentRefer,

  "campaign-faq": routerNameChallengeFaq,
  campaign_faq_challenges: routerNameChallengeFaq,
  campaign_faq_crate: routerNameChallengeFaq,
  campaign_faq_edit: routerNameChallengeFaq,

  "campaign-rule": routerNameChallengeRule,
  campaign_rule_challenges: routerNameChallengeRule,
  campaign_rule_crate: routerNameChallengeRule,
  campaign_rule_edit: routerNameChallengeRule,
  campaign_rule_banner: routerNameLadiRule,
  campaign_rule_gifts: routerNameLadiGift,

  "campaign-combo": routerNameCombo,
  combo_campaign: routerNameCombo,
  campaign_combo_create: routerNameCombo,
  campaign_combo_edit: routerNameCombo,

  "campaign-category": routerNameCategory,
  campaign_category_challenges: routerNameCategory,
  campaign_category_crate: routerNameCategory,
  campaign_category_edit: routerNameCategory,

  "campaign-challenge": routerNameChallenge,
  challenge_campaign: routerNameChallenge,
  campaign_challenge_create: routerNameChallenge,
  campaign_challenge_edit: routerNameChallenge,
  campaign_challenge_clone: routerNameChallenge,

  "campaign-mission": routerNameMission,
  mission_campaign: routerNameMission,
  campaign_mission_create: routerNameMission,
  campaign_mission_edit: routerNameMission,
  "campaign-mileStone": routerNameRank,
  mileStone: routerNameRank,
  mileStone_create: routerNameRank,
  mileStone_edit: routerNameRank,
  level_view: routerNameRank,

  campaign_report_refers: routerNameReferReport,
  campaign_report_refers_detail: routerNameReferReport,
  campaign_report_challenges: routerNameCampaignReport,
  campaign_report_missions: routerNameCampaignReport,
  campaign_report_gifts: routerNameCampaignReport,
  setting_campaign: routerNameCampaignSetting,
  setting_rank_config: routerNameCampaignSetting,
  combo_voucher_report_log_user_gifts: routerNameScriptCombo,
  combo_voucher_report_event_voucher_releases: routerNameScriptCombo,
  "top-service": routerNameTopService,
  "top-service-create": routerNameTopService,
  "top-service-edit": routerNameTopService,
  "top-service-analysis": routerNameTopService,
  "top-service-bot": routerNameTopService,
  "top-service-milestone": routerNameTopService,
  "top-service-export": routerNameTopService,

  //Giao dịch - Danh sách
  listTransactions: ["api/transaction/list"],
  detailTransactions: ["api/transaction/detail", "api/visa/add-white-list", "api/transaction/do-success", "api/transaction/partner-detail", "api/transaction/wait-to-refund", "api/transaction/offset", "api/transaction/reverse", "api/transaction/push-point"],
  statistics: ["api/statistics/rfm", "api/statistics/export"],
  rfm: ["api/statistics/rfm"],
  listExport: ["api/statistics/export"],
  "add-user": ["api/admin/roles/listAll"],
  reportCoupon: ["api/coupon", "api/coupon/export"],
  okr: [ "api/okr" , "api/okr/create" ],
  okrCreate: [ "api/okr/services", "api/okr/create" ],
  okrUpdate: [ "api/okr/services", "api/okr/view/{id}", "api/okr/update/{id}" ],
  segmentAction: [ "api/segment/action", "api/segment/action/approve", "api/segment/action/reject" ],
  segmentActionCreate: [ "api/segment/action/create", "api/cms/segment/listAvailable", "api/segment/action/import" ],
  segmentActionDetail: [ "api/segment/action/list-user/{id}" ],
  usersBlock: [ "api/account/list-account-lock", "api/account/unlock" ],
};

const routerNameAllow = ["login", "dashboard", "403", "api/okr/available", "ggauth"];
const ENV_PRODUCT = "product";

export default function permissionMiddleware(toName) {
  // Kiểm tra môi trường check quyền
  let appEnv = process.env.VUE_APP_ENV;
  if (appEnv != ENV_PRODUCT) {
    return true;
  }

  // let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));

  // Kiểm tra xem router có cần phải check quyền không
  if (routerNameAllow.includes(toName)) {
    return true;
  }

  // Kiểm tra xem routerName có quyền truy cập api theo userInfo không
  var listPermissionUserInfo = window.localStorage.getItem("permission");
  listPermissionUserInfo = JSON.parse(listPermissionUserInfo);

  // Nếu không khai báo thì không được truy cập
  if (typeof routerNameCheckPermission[toName] === "undefined") {
    return false;
  } else {
    let checkIsset = hasCommonElements(
      routerNameCheckPermission[toName],
      listPermissionUserInfo
    );
    if (checkIsset) {
      return true;
    }
  }
  return false;
}

function hasCommonElements(arr1, arr2) {
  for (let i = 0; i < arr1.length; i++) {
    if (arr2.includes(arr1[i])) {
      return true; // Tồn tại phần tử trùng
    }
  }
  return false; // Không tồn tại phần tử trùng
}
