<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Danh sách thẻ ATM</h6>
              </template>
              <div class="filter-transaction">
                <div class="row mb-6">
                  <div class="col-lg-3 mb-lg-0 mb-6">
                    <label>Số điện thoại:</label>
                    <input
                      type="number"
                      class="form-control datatable-input"
                      v-model="filter.phone"
                      placeholder="Nhập số điện thoại"
                    />
                  </div>
                  <div class="col-lg-3 mb-lg-0 mb-6">
                    <label>Đầu thẻ:</label>
                    <input
                      type="text"
                      class="form-control datatable-input"
                      v-model="filter.firstNumber"
                      placeholder="Nhập 6 số đầu của số thẻ"
                    />
                  </div>
                  <div class="col-lg-3 mb-lg-0 mb-6">
                    <label>Đuôi thẻ:</label>
                    <input
                      type="text"
                      class="form-control datatable-input"
                      v-model="filter.lastNumber"
                      placeholder="Nhập 4 số cuối của số thẻ"
                    />
                  </div>
                  <div class="col-lg-3 mb-lg-0 mb-6">
                    <label>Trạng thái</label>
                      <b-form-select v-model="filter.status" >
                          <option value="">Tất cả</option>
                          <option value="ACTIVE">Kích hoạt</option>
                          <option value="DEACTIVATED">Chưa kích hoạt</option>
                          <option value="CREATED">Mới tạo</option>
                      </b-form-select>
                  </div>
                  <div class="row mt-8">
                    <div class="col-lg-12 text-right">
                      <button
                        class="btn btn-primary btn-primary--icon"
                        @click="getList"
                      >
                        <span>
                          <i class="la la-search"></i>
                          <span>Tìm kiếm</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <b-table
                table-class="datatable-table"
                hover
                tbody-tr-class="datatable-row"
                thead-tr-class="datatable-row"
                thead-class="datatable-head"
                details-td-class="datatable-cell"
                :items="items"
                :fields="fields"
              >
                  <template #cell(status)="data">
                      <span>
                          <span v-if="data.value === 'ACTIVE'">Kích hoạt</span>
                          <span v-if="data.value === 'CREATED'">Mới tạo</span>
                          <span v-if="data.value === 'DEACTIVATED'">Chưa kích hoạt</span>
                      </span>
                  </template>
                <template #cell(whitelist)="data">
                    <a v-on:click="changeWhiteList(data,'DEACTIVATED')" class="btn btn-danger" href="javascript:;" v-if="data.value === 'ACTIVE'">Bỏ kích hoạt</a>
                    <a v-on:click="changeWhiteList(data,'ACTIVE')" class="btn btn-success" href="javascript:;" v-if="data.value !== 'ACTIVE'">Kích hoạt</a>
                </template>
                <template #cell(detail)="data">
                  <a :href="`${data.value}`">Xem</a>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination-nav
                  v-model="currentPage"
                  :link-gen="createLinkPage"
                  :number-of-pages="paginate.totalPage"
                  use-router
                  first-number
                  last-number
                  align="right"
                ></b-pagination-nav>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const TransactionRepository = RepositoryFactory.get("transactions");
const BankRepository = RepositoryFactory.get("bank");

export default {
  mixins: [Common],
  data() {
    return {
      filter: {},
      fields: [
        {
          key: "id",
          label: "ID",
        },
          {
              key: "user_id",
              label: "User ID",
          },
        {
          key: "card_number",
          label: "Số thẻ",
        },
        {
          key: "card_name",
          label: "Họ tên chủ thẻ",
        },
        {
          key: "hash_card",
          label: "Hash thẻ",
        },
        {
          key: "phone",
          label: "Số điện thoại",
        },
        {
          key: "status",
          label: "Trạng thái",
        },
          {
              key: "whitelist",
              label: "Trạng thái Whitelist",
          },
        {
          key: "detail",
          label: "Chi tiết",
        },
      ],
        options:{
            status:{
                'ACTIVE':'Whitelist',
                'DEACTIVATED':'Chưa whitelist',
                'CREATED':'Mới tạo',
            }
        },
      items: [],
      currentPage: 1,
      paginate: {
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách thẻ ATM", route: "list" },
    ]);
    this.filter = this.$route.query;
    this.getList();
  },
  // created() {
  //   if (this.$route.query.page > 1) {
  //     this.currentPage = this.$route.query.page;
  //   }
  // },
  methods: {
    async getList() {
      try {
          this.$bus.$emit("show-loading", true);
          let params = this.filter;
          params.page = this.currentPage;
          let request = await BankRepository.getListBankCards(params);
          this.$bus.$emit("show-loading", false);
          let response = request.data;
          if (response.error_code === 0) {
            response.data.data.forEach((value) => {
              value["detail"] = "/#/bank-card/detail?id=" + value.id;
              value["whitelist"] = value.status;
            });
            this.items = response.data.data;
            this.currentPage = response.data.currentPage;
            this.paginate.total = response.data.total;
            this.paginate.totalPage = response.data.lastPage;
          } else {
            alert(response.message);
          }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
      async changeWhiteList(data,status) {
          if (confirm("Xác nhận đổi trạng thái Whitelist?")) {
              this.$bus.$emit("show-loading", true);
              let params = {
                  transaction_id: data.item.trans_id,
                  status:status
              };
              let response = await TransactionRepository.addWhiteListPaymentInternational(params);
              this.$bus.$emit("show-loading", false);
              alert(response.data.message);
              if (response.data.error_code === 0) {
                  window.location.reload();
              }
          }
      },
  },
  computed: {
    firstNumber() {
      return this.filter.firstNumber;
    },
    lastNumber() {
      return this.filter.lastNumber;
    },
    phone() {
      return this.filter.phone;
    },
      status() {
          return this.filter.status;
      },
  },
  watch: {
    // $route: "getList",
    currentPage() {
      this.getList();
    },
    firstNumber(val) {
      if (this.filter.hasOwnProperty("firstNumber") && val.length < 1) {
        this.$delete(this.filter, "firstNumber");
      }
    },
    lastNumber(val) {
      if (this.filter.hasOwnProperty("lastNumber") && val.length < 1) {
        this.$delete(this.filter, "lastNumber");
      }
    },
    phone(val) {
      if (this.filter.hasOwnProperty("phone") && val.length < 1) {
        this.$delete(this.filter, "phone");
      }
    },
      status(val) {
          if (this.filter.hasOwnProperty("status") && val.length < 1) {
              this.$delete(this.filter, "status");
          }
      },
  },
};
</script>
