<template>
  <div>
    <div
      show
      variant="light"
      class="
        alert alert-custom alert-white alert-shadow
        fade
        show
        gutter-b
        justify-content-end
      "
    >
      <b-col md="4">
        <b-container fluid>
          <b-col md="9"><label :for="`date`">Từ ngày:</label></b-col>
          <b-col md="9">
            <b-form-input
                :id="`type-date`"
                :type="`date`"
                v-model="dateFrom"
            ></b-form-input>
          </b-col>
        </b-container>
      </b-col>

      <b-col md="4">
        <b-container fluid>
          <b-col md="9"><label :for="`date`">Đến ngày:</label></b-col>
          <b-col md="9">
            <b-form-input
              :id="`type-date`"
              :type="`date`"
              v-model="dateTo"
            ></b-form-input>
          </b-col>
        </b-container>
      </b-col>

      <b-col md="4">
        <b-col md="9" class="pt-2"><label ></label></b-col>
        <b-col class="text-right">
          <b-button-group>
            <b-button type="button" variant="primary" @click="getReport()">
              <i class="la la-search"></i>
              <span>Tìm kiếm</span>
            </b-button>

            <b-button type="button" variant="success" @click="$bvModal.show(modalId)">
              <i class="la la-file-export"></i>
              <span>Xuất file</span>
            </b-button>
          </b-button-group>
        </b-col>
      </b-col>
    </div>

    <b-card-text v-if="errorMessage" align="center" class="text-danger">{{
        errorMessage
      }}</b-card-text>

    <b-card>
      <b-card-body>
        <p v-if="!data">Không có dữ liệu</p>
        <div v-else class="table-responsive text-center">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>Time</th>
                <th>Phí trả thu/chi hộ</th>
                <th>Phí trả cổng</th>
                <th>Phí trả NH</th>
                <th>Phí trả KH</th>
                <th>Tổng phí trả</th>
                <th>Phí thu thu/chi hộ</th>
                <th>Phí thu cổng</th>
                <th>Phí thu đối tác</th>
                <th>Phí thu KH</th>
                <th>Phí thu NCC dịch vụ</th>
                <th>Tổng phí thu</th>
                <th>Phí hưởng</th>
              </tr>
            </thead>
            <tbody>
              <tr v-bind:key="key" v-for="(item, key) in data">
                <td>{{ key }}</td>
                <td>{{ item.EXPENSE && typeof item.EXPENSE.COLLECT_AND_PAY !== 'undefined' ? Number(item.EXPENSE.COLLECT_AND_PAY).toLocaleString() : 0 }}</td>
                <td><button @click="openPayGateDetail(key)">{{ item.EXPENSE && typeof item.EXPENSE.PAYGATE !== 'undefined' ? Number(item.EXPENSE.PAYGATE).toLocaleString() : 0 }}</button></td>
                <td><button @click="openBankDetail(key)">{{ item.EXPENSE && typeof item.EXPENSE.BANK !== 'undefined' ? Number(item.EXPENSE.BANK).toLocaleString() : 0 }}</button></td>
                <td>{{ item.EXPENSE && typeof item.EXPENSE.CUSTOMER !== 'undefined' ? Number(item.EXPENSE.CUSTOMER).toLocaleString() : 0 }}</td>
                <td>{{ item.total && typeof item.total.EXPENSE !== 'undefined' ? Number(item.total.EXPENSE).toLocaleString() : 0 }}</td>
                <td>{{ item.RECEIVED && typeof item.RECEIVED.COLLECT_AND_PAY !== 'undefined' ? Number(item.RECEIVED.COLLECT_AND_PAY).toLocaleString() : 0 }}</td>
                <td>{{ item.RECEIVED && typeof item.RECEIVED.PAYGATE !== 'undefined' ? Number(item.RECEIVED.PAYGATE).toLocaleString() : 0 }}</td>
                <td>{{ item.RECEIVED && typeof item.RECEIVED.PARTNER !== 'undefined' ? Number(item.RECEIVED.PARTNER).toLocaleString() : 0 }}</td>
                <td>{{ item.RECEIVED && typeof item.RECEIVED.CUSTOMER !== 'undefined' ? Number(item.RECEIVED.CUSTOMER).toLocaleString() : 0 }}</td>
                <td><button @click="openProviderDetail(key)">{{ item.RECEIVED && typeof item.RECEIVED.SERVICE_PROVIDER !== 'undefined' ? Number(item.RECEIVED.SERVICE_PROVIDER).toLocaleString() : 0 }}</button></td>
                <td>{{ item.total && typeof item.total.RECEIVED !== 'undefined' ? Number(item.total.RECEIVED).toLocaleString() : 0 }}</td>
                <td>{{ checkRevenue(item) }}</td>
              </tr>
            </tbody>
            <tfoot>
            <tr>
              <td style="font-weight: bold;">Tổng</td>
              <td style="font-weight: bold;">{{ calculateTotalByPath('EXPENSE.COLLECT_AND_PAY') }}</td>
              <td style="font-weight: bold;">{{ calculateTotalByPath('EXPENSE.PAYGATE') }}</td>
              <td style="font-weight: bold;">{{ calculateTotalByPath('EXPENSE.BANK') }}</td>
              <td style="font-weight: bold;">{{ calculateTotalByPath('EXPENSE.CUSTOMER') }}</td>
              <td style="font-weight: bold;">{{ calculateTotalByPath('total.EXPENSE') }}</td>
              <td style="font-weight: bold;">{{ calculateTotalByPath('RECEIVED.COLLECT_AND_PAY') }}</td>
              <td style="font-weight: bold;">{{ calculateTotalByPath('RECEIVED.PAYGATE') }}</td>
              <td style="font-weight: bold;">{{ calculateTotalByPath('RECEIVED.PARTNER') }}</td>
              <td style="font-weight: bold;">{{ calculateTotalByPath('RECEIVED.CUSTOMER') }}</td>
              <td style="font-weight: bold;">{{ calculateTotalByPath('RECEIVED.SERVICE_PROVIDER') }}</td>
              <td style="font-weight: bold;">{{ calculateTotalByPath('total.RECEIVED') }}</td>
              <td style="font-weight: bold;">{{ totalRevenue() }}</td>
            </tr>
            </tfoot>

          </table>
        </div>
      </b-card-body>
    </b-card>

    <alert-dialogue ref="confirmDialogue"></alert-dialogue>
    <export-modal :modal-id="modalId" @exportData="exportData"></export-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import AlertDialogue from "@/view/pages/components/AlertModal.vue";
import ExportModal from "@/view/pages/components/ExportModal.vue";
const FeeRepository = RepositoryFactory.get("fee");

export default {
  name: "Report",
  components: {ExportModal, AlertDialogue},
  mixins: [Common],
  data() {
    return {
      modalId: "export_report_fee",
      data: {},
      dateFrom: this.getFirstDayOfMonth(),
      dateTo: this.getCurrentDay(),
      errorMessage: "Vui lòng chọn thời gian",
    };
  },
  methods: {
    openBankDetail(date = null){
        window.open('/#/report/bank?date_from=' + date + '&date_to=' + date);
    },
    openPayGateDetail(date = null){
      window.open('/#/report/paygate?date_from=' + date + '&date_to=' + date);
    },
    openProviderDetail(date = null){
      window.open('/#/report/provider?date_from=' + date + '&date_to=' + date);
    },
    getReport() {
      this.$bus.$emit("show-loading", true);
      this.data = {};
      this.errorMessage = null;
      return FeeRepository.report({
        date_from: this.dateFrom,
        date_to: this.dateTo,
      })
        .then((response) => {
          if (response.status !== 200 || response.data.error_code === 1) {
            return (this.errorMessage = "Có lỗi khi lấy thông tin");
          }
          this.data = response.data.data;
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          return (this.errorMessage = "Có lỗi khi lấy thông tin");
        });
    },
    async exportData(message = null)
    {
      this.$bvModal.hide(this.modalId)
      this.$bus.$emit("show-loading", true);
      this.errorMessage = null;
      let self = this;
      return FeeRepository.exportData({
        date_from: this.dateFrom,
        date_to: this.dateTo,
        exportDesc: message,
      }).then(async (response) => {
        if (response.status !== 200 || response.data.error_code === 1) {
          self.$bus.$emit("show-loading", false);
          return self.errorMessage = "Có lỗi khi lấy thông tin";
        }
        let link = window.location.origin + "/#/reconcile/export-data";

        self.$bus.$emit("show-loading", false);
        const cfm = await this.$refs.confirmDialogue.show({
          title: "Download File",
          message: "File đang được xử lý, bạn có thể tải xuống sau ít phút <a href='" + link + "' target='_blank'>TẠI ĐÂY</a>",
          okButton: "Ok",
        });

        if (!cfm) {
          this.$bus.$emit("show-loading", false);
          return self.errorMessage = "Có lỗi khi lấy thông tin";
        }

        this.$bus.$emit("show-loading", false);
      })
      .catch(() => {
        this.$bus.$emit("show-loading", false);
        return self.errorMessage = "Có lỗi khi lấy thông tin";
      });
    },
    checkRevenue(item)
    {
      let received = typeof item.total.RECEIVED !== 'undefined' ? item.total.RECEIVED : 0;
      let expense = typeof item.total.EXPENSE !== 'undefined' ? item.total.EXPENSE : 0;
      let revenue = received - expense;

      return revenue !== 0 ? Number(revenue).toLocaleString() : 0;
    },
    calculateTotalByPath(path) {
      const getValueByPath = (obj, path) => {
        return path.split('.').reduce((o, key) => (o && o[key] !== undefined ? o[key] : undefined), obj);
      };

      return Object.values(this.data).reduce((sum, item) => {
        const value = getValueByPath(item, path);
        return sum + (value !== undefined ? Number(value) : 0);
      }, 0).toLocaleString();
    },
    totalRevenue(){
      return Object.values(this.data).reduce((sum, item) => {
        let received = typeof item.total.RECEIVED !== 'undefined' ? item.total.RECEIVED : 0;
        let expense = typeof item.total.EXPENSE !== 'undefined' ? item.total.EXPENSE : 0;
        let revenue = received - expense;

        return sum + revenue;
      }, 0).toLocaleString();
    }

  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
  },
  created() {
    this.getReport();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Báo cáo tổng hợp phí" }]);
  },
};
</script>
