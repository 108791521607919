<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-4">Thêm bảo trì ngân hàng</h6>
          <b-tabs class="mb-0">
            <b-tab
              @click="params.type_maintenance = 1"
              title="Luồng liên kết"
            ></b-tab>
            <b-tab
              @click="params.type_maintenance = 2"
              title="Luồng Ví-Bank"
            ></b-tab>
            <b-tab
              @click="params.type_maintenance = 3"
              title="Luồng thanh toán qua Cổng"
            ></b-tab>
          </b-tabs>
        </template>
        <div>
          <b-row>
            <b-col class="col-md-6">
              <b-form-group label="Tiêu đề (*)">
                <b-form-input v-model="params.note"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col
              class="col-md-6"
              v-if="
                parseInt(params.type_maintenance) === 1 ||
                parseInt(params.type_maintenance) === 2
              "
            >
              <div class="form-group">
                <label>Ngân hàng (*)</label>
                <span
                  style="text-decoration: underline !important;"
                  @click="handleSelect"
                  class="ml-2 text-reset cursor-pointer"
                  >{{ isAllSelected ? "Bỏ chọn tất cả" : "Chọn tất cả" }}</span
                >
                <multiselect
                  v-if="object"
                  v-model="params.bank_id"
                  :options="object"
                  :multiple="true"
                  :taggable="true"
                  label="text"
                  track-by="text"
                ></multiselect>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="parseInt(params.type_maintenance) === 1">
            <b-col class="col-md-6">
              <b-form-group label="Loại liên kết (*)">
                <b-form-select
                  v-model="params.link_type"
                  :options="linkType"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col class="col-md-6">
              <b-form-group label="Loại dịch vụ (*)">
                <b-form-select
                  v-model="params.type_service"
                  :options="serviceType"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-md-6">
              <b-form-group label="Thời gian bắt đầu(*)">
                <date-picker
                  id="end_date"
                  v-model="params.start_time"
                  type="datetime"
                  placeholder="Chọn thời gian"
                  :input-attr="{ required: 'true', name: '' }"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-type="format"
                ></date-picker>
              </b-form-group>
            </b-col>
            <b-col class="col-md-6">
              <b-form-group label="Thời gian kết thúc(*)">
                <date-picker
                  id="end_date"
                  v-model="params.end_time"
                  type="datetime"
                  placeholder="Chọn thời gian"
                  :input-attr="{ required: 'true', name: '' }"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-type="format"
                ></date-picker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="text-center">
                <b-button
                  type="button"
                  variant="primary"
                  class="mr-2"
                  @click="createBankMaintenance()"
                  ><i class="fas fa-save"></i> Lưu</b-button
                >
                <b-button type="button" variant="danger" @click="cancel()"
                  ><i class="fas fa-times"></i> Hủy</b-button
                >
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "../../../../core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Vue from "vue";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

Vue.component("multiselect", Multiselect);
Vue.use(Notifications);

const CmsRepository = RepositoryFactory.get("cms");

export default {
  mixins: [Common],
  components: { Multiselect, DatePicker },
  data() {
    return {
      isAllSelected: false,
      items: {},
      params: {
        note: null,
        bank_id: [],
        link_type: null,
        type_service: null,
        start_time: null,
        end_time: null,
        type_maintenance: 1,
      },
      bank_params: {
        per_page: 1000,
      },
      object: [],
      linkType: [
        { text: "Tất cả", value: "ALL" },
        { text: "LK trực tiếp", value: "DIRECT_LINK" },
        { text: "LK Napas", value: "NAPAS_PAYGATE" },
      ],
      serviceType: [
        { text: "Tất cả", value: "ALL" },
        { text: "Nạp tiền", value: "RECHARGE" },
        { text: "Rút tiền", value: "WITHDRAW" },
        { text: "Liên kết", value: "LINK" },
        { text: "Hủy liên kết", value: "UNLINK" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Thêm bảo trì ngân hàng", route: "create" },
    ]);
  },
  methods: {
    async createBankMaintenance() {
      this.$bus.$emit("show-loading", true);

      if (!this.params.note) {
        this.$bus.$emit("show-loading", false);
        this.notifyAlert("warn", "Tiêu đề không được để trống");
        return false;
      }

      if (
        (parseInt(this.params.type_maintenance) === 1 ||
          parseInt(this.params.type_maintenance) === 2) &&
        this.params.bank_id.length <= 0
      ) {
        this.$bus.$emit("show-loading", false);
        this.notifyAlert("warn", "Bank không được để trống");
        return false;
      }

      if (
        parseInt(this.params.type_maintenance) === 1 &&
        !this.params.link_type
      ) {
        this.$bus.$emit("show-loading", false);
        this.notifyAlert("warn", "Loại liên kết không được để trống");
        return false;
      }

      if (
        parseInt(this.params.type_maintenance) === 1 &&
        !this.params.type_service
      ) {
        this.$bus.$emit("show-loading", false);
        this.notifyAlert("warn", "Loại dịch vụ không được để trống");
        return false;
      }

      if (!this.params.start_time || !this.params.end_time) {
        this.$bus.$emit("show-loading", false);
        this.notifyAlert("warn", "Thời gian không được để trống");
        return false;
      }

      let params = this.convert(this.params);
      if (!params.bank_id) {
        params.bank_id = [];
      }
      let response = await CmsRepository.createBankMaintenance(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;

      if (body.error_code > 0) {
        this.notifyAlert("warn", body.message);
        return true;
      }

      this.notifyAlert("success", body.message);
      window.location.href = "#/tools/bank/maintenance";
    },
    handleSelect() {
      this.isAllSelected = !this.isAllSelected;

      if (this.isAllSelected) {
        this.params.bank_id = [...this.object];
      } else {
        this.params.bank_id = [];
      }
    },
    async getBankProvider() {
      this.object = [];
      this.$bus.$emit("show-loading", true);

      let response = await CmsRepository.listBank(this.bank_params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;

      if (body.error_code > 0) {
        this.notifyAlert("warn", body.message);
        return true;
      }

      let banks = body.data.data;
      let object = [];
      banks.forEach(function (item) {
        object.push({
          value: item.id,
          text: item.bank_code + ` - (${item.full_name})`,
        });
      });

      this.object = object;
    },
    cancel() {
      window.location = "/#/tools/bank/maintenance";
    },
    convert(params) {
      Object.keys(params).forEach((key) => {
        if (params[key] === null || params[key] == "") {
          delete params[key];
        }
      });
      return params;
    },
  },
  created() {
    this.getBankProvider();
  },
  watch: {},
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-datepicker/index.css"></style>

<style>
.mx-datepicker {
  width: 100% !important;
}
.card-header {
  padding-bottom: 0 !important;
}
</style>
